<template>
  <div class="row">
    <div class="col-12 col-xl-6 mx-auto">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">New Project</h5>
          <h6 class="card-subtitle text-muted">Create a new project.</h6>
        </div>
        <div class="card-body">
          <form @submit.prevent="create">
            <b-form-group label="Name">
              <b-form-input
                type="text"
                placeholder="e.g. My New Project"
                v-model="project.name"
                @input="updatePath"
                required
                trim
              />
            </b-form-group>
            <b-form-group label="Path">
              <b-form-input
                type="text"
                placeholder="e.g. my-new-project"
                v-model="path"
                required
                trim
              />
            </b-form-group>
            <b-form-group label="Description">
              <b-form-textarea
                v-model="project.description"
                placeholder="e.g. This is a description of my new project..."
                rows="4"
                max-rows="4"
              ></b-form-textarea>
            </b-form-group>
            <b-button type="submit" variant="primary">
              <span v-if="!processing">Create</span
              ><span v-if="processing" class="spinner"
                ><b-spinner small></b-spinner
              ></span>
            </b-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Projects from "@/firebase/projects";
import slugify from "slugify";

export default {
  name: "ProjectNew",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  // components: {
  //   Avatar,
  // },
  data() {
    return {
      bind: ["slugs"],
      project: {
        name: "",
        description: "",
        path: "",
        owner: "",
        members: [],
        editors: [],
      },
      pathFormat: new RegExp(/([a-z0-9-])+/g),
      processing: false,
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["userDisplayName", "userAvatar"]),
    path: {
      get() {
        return this.project.path;
      },
      set(path) {
        const format = new RegExp(/[^\\A-Za-z0-9-]+/);
        const updated = path.replace(format, "").toLowerCase();
        this.$set(this.project, "path", updated);
      },
    },
    validatePath() {
      const path = this.project.path;
      if (path.length === 0) {
        return null;
      }
      if (path in this.slugs) {
        return false;
      }
      if (path.length < 3) {
        return false;
      }
      if (this.pathFormat.test(path) === false) {
        return false;
      }
      return true;
    },
  },
  methods: {
    create() {
      const project = { ...this.project };
      this.processing = true;

      const p = new Projects();
      p.create(project)
        .then((resp) => {
          this.processing = false;
          this.notyf.success("Project created successfully");
          this.navigate("projects-edit", { slug: resp.data.path });
        })
        .catch((error) => {
          this.processing = false;
          console.log(error.response);
          if (error.response.status === 429) {
            this.notyf.error(
              "Unable to create project: Plan limit of owned projects exceeded"
            );
          } else {
            this.notyf.error(
              "An error occurred while creating the project. Please try again later"
            );
          }
        });
    },
    getUniqueSlug() {
      let slug = slugify(this.project.name, { lower: true, strict: true });
      let modifier = 2;
      while (slug in this.slugs) {
        modifier++;
        slug = slug + "-" + modifier;
      }
      return slug;
    },
    updatePath() {
      this.project.path = this.getUniqueSlug();
    },
  },
};
</script>
