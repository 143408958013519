import FirebaseMixin from "./Firebase";
import { DateTime } from "luxon";

export default {
  mixins: [FirebaseMixin],
  data() {
    return {
      bind: [],
      usage: {
        apis: {},
        fake: {},
        data: {},
      },
    };
  },
  watch: {
    usage: {
      immediate: true,
      handler(usage) {
        if (!usage) {
          this.usage = {};
        }
        if (!usage.apis) {
          this.usage.apis = {};
        }
        if (!usage.fake) {
          this.usage.fake = {};
        }
        if (!usage.data) {
          this.usage.data = {};
        }
      },
    },
  },
  computed: {
    currentMonth() {
      const dt = DateTime.now();
      return dt.toFormat("yyyy-MM");
    },
    lastMonth() {
      const dt = DateTime.now().minus({ months: 1 });
      return dt.toFormat("yyyy-MM");
    },
    apiCalls() {
      return this.getApiCalls(this.currentMonth);
    },
    apiCallsLastMonth() {
      return this.getApiCalls(this.lastMonth);
    },
    apiCallsMonthOverMonth() {
      return this.calculateChange(this.apiCallsLastMonth, this.apiCalls);
    },
    apiUsage() {
      let limit;
      if (
        this.project &&
        "plan" in this.project &&
        "api_calls" in this.project.plan
      ) {
        limit = this.project.plan.api_calls;
      } else {
        limit = 1000;
      }
      const usage = Math.ceil((this.apiCalls / limit) * 100);
      return usage > 100 ? 100 : usage;
    },
    fake() {
      return this.getFakeCount(this.currentMonth);
    },
    fakeLastMonth() {
      return this.getFakeCount(this.lastMonth);
    },
    fakeMonthOverMonth() {
      return this.calculateChange(this.fakeLastMonth, this.fake);
    },
    fakeUsage() {
      let limit;
      if (
        this.project &&
        "plan" in this.project &&
        "fake" in this.project.plan
      ) {
        limit = this.project.plan.fake;
      } else {
        limit = 10000;
      }
      const usage = Math.ceil((this.fake / limit) * 100);
      return usage > 100 ? 100 : usage;
    },
    storage() {
      // if (Array.isArray(this.data) && this.data.length > 0) {
      //   return this.data.reduce((total, data) => {
      //     const size = !isNaN(data.size) ? Number(data.size) : 0;
      //     return total + size;
      //   }, 0);
      // } else {
      //   return 0;
      // }
      return this.getStorageCount(this.currentMonth);
    },
    storageLastMonth() {
      return this.getStorageCount(this.lastMonth);
    },
    storageMonthOverMonth() {
      return this.calculateChange(this.storageLastMonth, this.fake);
    },
    storageUsage() {
      let limit;
      if (
        this.project &&
        "plan" in this.project &&
        "storage" in this.project.plan
      ) {
        limit = this.project.plan.storage * 1024;
      } else {
        limit = 10240;
      }
      const usage = Math.ceil((this.storage / limit) * 100);
      return usage > 100 ? 100 : usage;
    },
    projectUsage() {
      return Math.max(...[this.apiUsage, this.fakeUsage, this.storageUsage]);
    },
    projectUsageColor() {
      if (this.projectUsage >= 75) {
        return "danger";
      } else if (this.projectUsage >= 50) {
        return "warning";
      } else {
        return "primary";
      }
    },
  },
  methods: {
    getApiCalls(month) {
      if (this.usage.apis && Object.keys(this.usage.apis).length > 0) {
        return Object.keys(this.usage.apis)
          .filter((date) => {
            return String(date) === String(month);
          })
          .reduce((total, date) => {
            return total + Number(this.usage.apis[date]);
          }, 0);
      } else {
        return 0;
      }
    },
    getFakeCount(month) {
      if (this.usage.fake && Object.keys(this.usage.fake).length > 0) {
        return Object.keys(this.usage.fake)
          .filter((date) => {
            return String(date) === String(month);
          })
          .reduce((total, date) => {
            return total + Number(this.usage.fake[date]);
          }, 0);
      } else {
        return 0;
      }
    },
    getStorageCount(month) {
      if (this.usage.data && Object.keys(this.usage.data).length > 0) {
        return Object.keys(this.usage.data)
          .filter((date) => {
            return String(date) === String(month);
          })
          .reduce((total, date) => {
            return total + Number(this.usage.data[date]);
          }, 0);
      } else {
        return 0;
      }
    },
    calculateChange(lastMonth, thisMonth) {
      let percent;
      if (lastMonth !== 0) {
        if (thisMonth !== 0) {
          percent = ((lastMonth - thisMonth) / thisMonth) * 100;
        } else {
          percent = lastMonth * 100;
        }
      } else {
        percent = -thisMonth * 100;
      }
      return Number(percent * -1).toFixed(2);
    },
  },
};
