<template>
  <div class="row">
    <div class="col-12 col-xl-6 mx-auto">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Edit Project</h5>
          <h6 class="card-subtitle text-muted">Edit project configuration.</h6>
        </div>
        <div class="card-body">
          <form @submit.prevent="save">
            <b-form-group label="Name">
              <b-form-input
                type="text"
                placeholder="e.g. My New Project"
                v-model="project.name"
                @input="updatePath"
                required
                trim
              />
            </b-form-group>
            <b-form-group label="Path">
              <b-form-input
                type="text"
                placeholder="e.g. my-new-project"
                v-model="path"
                required
                trim
              />
            </b-form-group>
            <b-form-group label="Description">
              <b-form-textarea
                v-model="project.description"
                placeholder="e.g. This is a description of my new project..."
                rows="8"
                max-rows="8"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group label="Image">
              <avatar
                :username="project.name"
                :src="project.image"
                :rounded="false"
                :size="128"
                class="rounded"
              ></avatar>
              <div class="mt-2">
                <label
                  class="btn btn-primary btn-file mr-1"
                  for="fileUploadAvatar"
                >
                  <b-spinner small v-if="uploading"></b-spinner>
                  <span class="sr-only" v-if="uploading">Loading...</span>
                  <i class="fas fa-upload mr-1" v-if="!uploading"></i>
                  <span v-if="!uploading">Upload</span>
                  <input
                    id="fileUploadAvatar"
                    type="file"
                    @change="uploadImage"
                  />
                </label>
                <b-button
                  variant="danger"
                  class="ml-1"
                  @click="deleteImage"
                  v-if="project.image"
                >
                  <i class="far fa-trash-alt"></i> Remove
                </b-button>
              </div>
              <small
                >For best results, use an image at least 128px by 128px in .jpg
                format</small
              >
            </b-form-group>
            <b-button type="submit" variant="primary" :disabled="!canEdit"
              >Save</b-button
            >
          </form>
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-6 mx-auto">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Manage Access</h5>
          <h6 class="card-subtitle text-muted">
            Manage project members and permissions.
          </h6>
        </div>
        <div class="card-body">
          <b-input-group class="mb-3">
            <b-form-input
              v-model="invite.email"
              placeholder="Email Address"
              :disabled="!canEdit"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                variant="primary"
                class="rounded-right"
                @click="sendInvite"
                :disabled="!canEdit"
              >
                <span v-if="sending">
                  <b-spinner small></b-spinner>
                  Sending </span
                ><span v-if="!sending">Send Invite</span></b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>
        <ul class="list-group mx-2 mb-3">
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
            v-for="(member, index) in memberList"
            :key="'member-' + index"
          >
            <span
              >{{ member.name
              }}<small class="ml-2"
                ><span v-if="member.owner"><i>Owner</i></span></small
              ></span
            >
            <div class="member-buttons">
              <b-form inline>
                <span class="mr-1">Owner</span>
                <b-form-checkbox
                  switch
                  :disabled="member.uid === userProfile.uid || owner === false"
                  v-model="member.owner"
                  class="mr-2"
                  @change="makeOwner(member)"
                >
                </b-form-checkbox>
                <span
                  class="mr-1"
                  v-if="owner && member.uid !== userProfile.uid"
                  >Editor</span
                >
                <b-form-checkbox
                  switch
                  v-if="owner && member.uid !== userProfile.uid"
                  v-model="member.canEdit"
                  :disabled="member.uid === userProfile.uid"
                  class="mr-2"
                  @change="makeEditor(member)"
                >
                </b-form-checkbox>
                <b-button
                  variant="danger"
                  size="sm"
                  v-if="owner && member.uid !== userProfile.uid"
                >
                  <i class="far fa-trash-alt"></i>
                </b-button>
              </b-form>
            </div>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
            v-for="(member, index) in pendingList"
            :key="'pending-' + index"
          >
            <span
              >{{ member.name
              }}<small class="ml-2"
                ><span><i>Pending</i></span></small
              ></span
            >
            <div class="member-buttons">
              <b-button variant="danger" size="sm" v-if="canEdit">
                <i class="far fa-trash-alt"></i>
              </b-button>
            </div>
          </li>
        </ul>
      </div>

      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Export Project</h5>
          <h6 class="card-subtitle text-muted">
            Export project as a JSON file.
          </h6>
        </div>
        <div class="card-body">
          <b-button variant="primary" v-b-modal.export_modal>
            <i class="fas fa-download"></i> Export Project
          </b-button>
          <b-modal
            id="export_modal"
            title="Export Project"
            ok-title="Download"
            :ok-disabled="!exportReady"
            @show="exportProject"
            @ok="createExport"
            @hide="
              exportReady = false;
              exportBody = '';
            "
          >
            <template #modal-ok>
              <i class="fas fa-download"></i> Download
            </template>
            <span v-if="!exportReady">
              <b-spinner label="Spinning" small></b-spinner> Your export is
              being prepared. Please wait.
            </span>
            <span v-else>
              Your export is ready. Please click Download below.
            </span>
          </b-modal>

          <commit-to-github
            v-if="project.github_status === 'connected'"
            :project="{ key: refkey, ...project }"
            :path="project.github_path"
            :processing="githubProcessing"
            class="ml-1"
            @commit="commitProject"
          />
        </div>
      </div>

      <div class="card" v-if="project.plan.name !== 'Free Plan'">
        <div class="card-header">
          <h5 class="card-title">Github Integration</h5>
          <h6 class="card-subtitle text-muted">
            Integrate with Github to enable committing projects and scripts to a
            git repository.
          </h6>
        </div>
        <div class="card-body" v-if="project.github_status !== 'connected'">
          <b-button
            variant="primary"
            :href="
              'https://github.com/login/oauth/authorize?client_id=' +
              config.github_client_id +
              '&scope=repo' +
              '&redirect_uri=' +
              redirectUri +
              '&state=' +
              redirectState
            "
          >
            <i class="fab fa-github"></i> Connect to Github
          </b-button>
        </div>
        <div class="card-body" v-if="project.github_status === 'connected'">
          Connected to Github as <b>{{ project.github_username }}</b>
          <span class="float-right">
            <b-button variant="primary" class="mr-1" v-b-modal.github_modal>
              <i class="fas fa-edit"></i> Manage
            </b-button>

            <b-modal
              id="github_modal"
              title="Manage Github Integration"
              ok-title="Save"
              @ok="saveRepo"
            >
              <template #modal-ok> <i class="fas fa-save"></i> Save </template>
              Please select a git repository:
              <b-form-select
                :options="repos"
                v-model="repo"
                value-field="name"
                text-field="name"
                class="mt-2"
              />
            </b-modal>

            <b-button variant="danger" v-b-modal.github_disconnect_modal>
              <i class="fas fa-times-circle"></i> Disconnect
            </b-button>

            <b-modal
              id="github_disconnect_modal"
              title="Disconnect Github Integration"
              ok-title="Disconnect"
              ok-variant="danger"
              @ok="disconnectGithub"
            >
              <template #modal-ok>
                <i class="fas fa-times-circle"></i> Disconnect
              </template>
              Are you sure you want to disconnect your project from Github?
              <small class="d-block mt-3">
                Please note: We recommend revoking access to API Faker Github
                Integration from within your
                <b-link href="https://github.com/settings/applications"
                  >Github account</b-link
                >. This can be found under Settings > Applications > Authorized
                OAuth Apps.
              </small>
            </b-modal>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Projects from "@/firebase/projects";
import Github from "@/firebase/github";
import slugify from "slugify";
import { v4 as uuidv4 } from "uuid";
import Avatar from "vue-avatar";
import { DateTime } from "luxon";
import CommitToGithub from "@/components/CommitToGithub";

export default {
  name: "ProjectEdit",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    Avatar,
    CommitToGithub,
  },
  data() {
    return {
      bind: ["slugs"],
      refkey: "",
      project: {
        name: "",
        description: "",
        path: "",
        owner: "",
        members: {},
        editors: {},
        plan: {},
        github_status: "",
        github_username: "",
        github_repo: "",
        github_path: "",
      },
      membership: {},
      pending: {},
      invite: {
        email: "",
      },
      pathFormat: new RegExp(/([a-z0-9-])+/g),
      uploading: false,
      sending: false,
      exportReady: false,
      exportBody: "",
      repos: [],
      repo: "",
      githubProcessing: false,
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "config"]),
    ...mapGetters(["userAuthorName"]),
    path: {
      get() {
        return this.project.path;
      },
      set(path) {
        const format = new RegExp(/[^\\A-Za-z0-9-]+/);
        const updated = path.replace(format, "").toLowerCase();
        this.$set(this.project, "path", updated);
      },
    },
    validatePath() {
      const path = this.project.path;
      if (path.length === 0) {
        return null;
      }
      if (path in this.slugs) {
        return false;
      }
      if (path.length < 3) {
        return false;
      }
      if (this.pathFormat.test(path) === false) {
        return false;
      }
      return true;
    },
    owner() {
      return this.project.owner === this.userProfile.uid;
    },
    canEdit() {
      return Object.keys(this.project.editors).includes(this.userProfile.uid);
    },
    memberList() {
      return Object.keys(this.membership).map((member) => {
        return {
          uid: member,
          ...this.membership[member],
          owner: this.project.owner === member,
          canEdit: Object.keys(this.project.editors).includes(member),
        };
      });
    },
    pendingList() {
      return Object.keys(this.pending).map((member) => {
        return { uid: member, ...this.pending[member] };
      });
    },
    redirectUri() {
      return window.location.href;
    },
    redirectState() {
      return uuidv4();
    },
  },
  watch: {
    refkey: {
      immediate: false,
      handler(key) {
        this.bindObject("projects", key, "project");
        this.bindObject("membership", key, "membership");
        this.bindObject("pending", key, "pending");
      },
    },
    project: {
      immediate: false,
      handler(project) {
        if (project.github_status === "connected") {
          if (this.repos.length === 0) {
            const g = new Github();
            g.getRepos(this.refkey).then((resp) => {
              this.repos = [...resp.data];
            });
          }
          this.repo = this.project.github_repo;
          if (!project.github_path) {
            const name = project.name.replace(/[/\\?%*:|"<> ]/g, "-");
            project.github_path = `Projects/${name}-${Date.now()}.json`;
          }
        }
      },
    },
  },
  methods: {
    save() {
      const project = { ...this.project };
      this.updateObject("projects", this.refkey, project)
        .then(() => {
          this.notyf.success("Project saved successfully.");
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while saving. Please try again later."
          );
        });
    },
    getUniqueSlug() {
      let slug = slugify(this.project.name, { lower: true, strict: true });
      let modifier = 2;
      while (slug in this.slugs) {
        modifier++;
        slug = slug + "-" + modifier;
      }
      return slug;
    },
    addSlug(key) {
      const slug = this.project.slug;
      this.slugs[slug] = key;
      const slugs = { ...this.slugs };
      return this.updateObject("slugs", null, slugs);
    },
    updatePath() {
      this.project.path = this.getUniqueSlug();
    },
    uploadImage() {
      const reader = new FileReader();
      const files = event.target.files;
      console.log(files);

      // Start button loading spinner
      this.uploading = true;

      if (files.length === 0) {
        // Stop button loading spinner
        this.uploading = false;
      } else {
        const file = files[0];
        const extension = file.name.split(".").pop();
        const filename = uuidv4() + "." + extension;
        console.log("File: " + filename);

        reader.addEventListener(
          "load",
          () => {
            const p = new Projects();
            p.uploadImage(this.refkey, filename, reader.result)
              .then(() => {
                this.getImageUrl(`projects/${this.refkey}/${filename}`)
                  .then((url) => {
                    this.updateObject("projects", this.refkey, {
                      image: url,
                      image_file: filename,
                    })
                      .then(() => {
                        this.uploading = false;
                      })
                      .catch((error) => {
                        this.uploading = false;
                        console.error("UploadImage", error);
                        this.notyf.error(
                          "Unable to upload image: Please try again later."
                        );
                      });
                  })
                  .catch((error) => {
                    this.uploading = false;
                    console.error("UploadImage", error);
                    this.notyf.error(
                      "Unable to upload image: Please try again later."
                    );
                  });
              })
              .catch((error) => {
                this.uploading = false;
                console.error("UploadImage", error);
                this.notyf.error(
                  "Unable to upload image: Please try again later."
                );
              });
          },
          false
        );

        if (file) {
          reader.readAsDataURL(file);
        }
      }
    },
    deleteImage() {
      if (this.project.image_file) {
        const p = new Projects();
        p.deleteImage(this.refkey)
          .then(() => {
            this.updateObject("projects", this.refkey, {
              image: "",
              image_file: "",
            }).catch((error) => {
              console.error("DeleteImage", error);
              this.notyf.error(
                "An error occurred while deleting the image. Please try again later."
              );
            });
          })
          .catch((error) => {
            console.log("DeleteImage", error);
            this.notyf.error(
              "An error occurred while deleting the image. Please try again later."
            );
          });
      }
    },
    sendInvite() {
      this.sending = true;
      const email = this.invite.email;
      const p = new Projects();
      p.sendInvite(this.refkey, email)
        .then(() => {
          this.invite.email = null;
          this.notyf.success("Invite sent successfully.");
          this.sending = false;
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while sending invite. Please try again later."
          );
          this.sending = false;
        });
    },
    makeOwner(member) {
      console.log(member.uid, member.canEdit);
      this.updateObject("projects/", this.refkey, { owner: member.uid })
        .then(() => {
          this.notyf.success("Owner updated successfully.");
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred updating the owner. Please try again later."
          );
        });
    },
    makeEditor(member) {
      console.log(member.uid, member.canEdit);
      let editors;
      if (member.canEdit) {
        editors = { ...this.project.editors, [member.uid]: true };
      } else {
        editors = { ...this.project.editors };
        delete editors[member.uid];
      }
      console.log("Editors", editors);
      this.updateObject(
        "projects/" + this.refkey + "/editors",
        null,
        editors,
        true
      )
        .then(() => {
          this.notyf.success("Access updated successfully.");
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred updating the access. Please try again later."
          );
        });
    },
    exportProject() {
      this.exportReady = false;
      const p = new Projects();
      p.export(this.refkey)
        .then((result) => {
          this.exportReady = true;
          this.exportBody = JSON.stringify(result.data, null, 2);
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while preparing the export. Please try again later."
          );
          this.exportReady = true;
        });
    },
    createExport() {
      if (this.exportReady) {
        // Create file date
        const dt = DateTime.now();
        const fileDate = dt.toFormat("yyyyLLddHHmmss");
        this.saveFile(`Project_Export_${fileDate}.json`, this.exportBody);
      }
    },
    saveRepo() {
      this.updateObject("projects", this.refkey, { github_repo: this.repo })
        .then(() => {
          this.notyf.success("Github settings saved successfully.");
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while saving. Please try again later."
          );
        });
    },
    disconnectGithub() {
      const g = new Github();
      g.disconnect(this.refkey)
        .then(() => {
          this.notyf.success("Github disconnected successfully.");
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while disconnecting. Please try again later."
          );
        });
    },
    commitProject(g) {
      this.githubProcessing = true;
      console.log(g);
      const p = new Projects();
      p.export(this.refkey)
        .then((result) => {
          g.commit(JSON.stringify(result.data, null, 2))
            .then(() => {
              this.githubProcessing = false;
              this.notyf.success("Project committed successfully.");
            })
            .catch((error) => {
              this.githubProcessing = false;
              console.log(error);
              this.notyf.error(
                "An error occurred while committing. Please try again later."
              );
            });
        })
        .catch((error) => {
          this.githubProcessing = false;
          console.log(error);
          this.notyf.error(
            "An error occurred while preparing the commit. Please try again later."
          );
        });
    },
  },
  created() {
    this.slug = this.$route.params.slug;
    this.getIdFromSlug(this.slug).then((id) => {
      this.refkey = id;
      // Check if redirected from Github OAuth service
      const params = new URLSearchParams(window.location.search);
      if (params.has("code")) {
        const g = new Github();
        console.log("Params", params.toString());
        g.register(this.refkey, params.get("code"), params.get("state"));
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.btn-file {
  position: relative;
  overflow: hidden;
  top: 4px;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: inherit;
  display: block;
}
</style>
