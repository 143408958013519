<template>
  <div class="d-inline-block">
    <b-button
      variant="secondary"
      v-b-modal.github_commit_modal
      :disabled="disabled"
      :title="disabled ? 'Please specify a repo' : null"
      :class="buttonClass"
    >
      <i class="fab fa-github"></i>&nbsp;
      <span v-if="!processing">Commit to Github</span
      ><span v-if="processing" class="spinner"
        ><b-spinner small></b-spinner
      ></span>
    </b-button>

    <b-modal
      id="github_commit_modal"
      title="Commit to Github"
      ok-title="Commit"
      :ok-disabled="path.length === 0 || message.length === 0"
      @ok="commit"
    >
      <template #modal-ok> <i class="fab fa-github"></i> Commit </template>
      Commit to <b>{{ project.github_repo }}</b>
      <b-form class="mt-3">
        <b-form-group label="Path">
          <b-form-input v-model="path" />
        </b-form-group>
        <b-form-group label="Commit Message">
          <b-form-textarea v-model="message" rows="4" max-rows="4" />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Github from "@/firebase/github";

export default {
  name: "CommitToGithub",
  props: {
    project: {
      type: Object,
      required: true,
    },
    path: {
      type: String,
      required: false,
    },
    processing: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonClass: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      message: "",
    };
  },
  computed: {
    disabled() {
      return (
        !("github_repo" in this.project) ||
        !this.project.github_repo ||
        this.project.github_repo.length === 0
      );
    },
  },
  methods: {
    commit() {
      const g = new Github();
      g.projectKey = this.project.key;
      g.owner = this.project.github_username;
      g.repo = this.project.github_repo;
      g.path = this.path;
      g.message = this.message;
      this.$emit("commit", g);
      this.message = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  display: inline-block;
  width: 118px;
}
</style>
