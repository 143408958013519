<template>
  <div>
    <div class="container-fluid" v-if="searchClient !== false">
      <ais-instant-search
        :search-client="searchClient"
        :index-name="searchIndex"
      >
        <b-dropdown
          split
          :split-to="{ name: 'projects-new' }"
          variant="primary"
          class="float-right mt-n1"
        >
          <template #button-content>
            <i class="fas fa-plus"></i> New project
          </template>
          <b-dropdown-item :to="{ name: 'projects-import' }"
            >Import project</b-dropdown-item
          >
        </b-dropdown>

        <form class="float-right mt-2 mt-md-n1 mr-md-2 mb-2 mb-md-0">
          <ais-search-box>
            <div slot-scope="{ currentRefinement, isSearchStalled, refine }">
              <input
                type="search"
                :value="currentRefinement"
                @input="refine($event.currentTarget.value)"
                placeholder="Search projects..."
                class="form-control"
              />
              <span :hidden="!isSearchStalled">Loading...</span>
            </div>
          </ais-search-box>
        </form>

        <h1 class="h3 mb-3">Projects</h1>

        <infinite-hits class="mt-4">
          <div
            slot-scope="{
              items,
              refinePrevious,
              refineNext,
              isFirstPage,
              isLastPage,
            }"
          >
            <div class="row w-100">
              <div class="col text-center my-3" v-if="!isFirstPage">
                <b-button @click="refinePrevious">
                  Show previous results
                </b-button>
              </div>
            </div>
            <div class="row w-100">
              <project-card
                v-for="project in items"
                :project="project"
                :key="project.objectID"
                :selected="project.objectID === selectedProject.objectID"
              ></project-card>
            </div>
            <div class="row w-100">
              <div class="col text-center my-3">
                <b-button @click="refineNext" v-if="!isLastPage" class="mx-1">
                  Show more results
                </b-button>
              </div>
            </div>
          </div>
        </infinite-hits>

        <ais-powered-by />
      </ais-instant-search>
    </div>
    <div class="container-fluid" v-if="searchClient === false">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <loader class="my-5" color="#3F80EA"></loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import ProjectCard from "@/components/ProjectCard";
import CustomInfiniteHits from "@/components/CustomInfiniteHits";
import Loader from "@/components/Loader";
import algoliasearch from "algoliasearch/lite";

export default {
  name: "Projects",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    "project-card": ProjectCard,
    "infinite-hits": CustomInfiniteHits,
    loader: Loader,
  },
  data() {
    return {
      bind: [],
      searchClient: false,
      selectedProject: {},
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "config"]),
    ...mapGetters(["searchKey", "searchConfig"]),
    userUid() {
      return this.userProfile.uid;
    },
    searchIndex() {
      return window.environment === "production"
        ? "prod_Projects"
        : "sandbox_Projects";
    },
    algoliaSearchKey() {
      return this.searchKey;
    },
    algoliaSearchConfig() {
      return this.searchConfig;
    },
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        if (uid) {
          this.bindObject("selected", uid, "selectedProject");
        }
      },
    },
    algoliaSearchKey: {
      immediate: true,
      handler(key) {
        console.log("SearchKey", key);
        const config = this.searchConfig;
        if (config.app_id !== "") {
          // Initialize Algolia
          this.searchClient = algoliasearch(config.app_id, key);
        }
      },
    },
    algoliaSearchConfig: {
      immediate: true,
      handler(config) {
        console.log("SearchConfig", config);
        const key = this.searchKey;
        if (config.app_id !== "" && key != "") {
          // Initialize Algolia
          this.searchClient = algoliasearch(config.app_id, key);
        }
      },
    },
  },
  methods: {},
  created() {},
};
</script>
