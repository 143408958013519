import axios from "axios";
import firebase from "firebase";

export default class Github {
  remoteUrl = "https://us-central1-api-faker.cloudfunctions.net/";
  //remoteUrl = "http://localhost:5001/api-faker/us-central1/";
  projectKey;
  path;
  message;
  owner;
  repo;

  // Fetch an id token from Firebase for API authentication
  async getToken() {
    const user = firebase.auth().currentUser;
    const response = await user.getIdToken(false);
    return response;
  }

  // Register Github connection for project using cloud function
  async register(project, code, state) {
    // Get token for authentication
    const token = await this.getToken();
    // Prepare url params
    const params = new URLSearchParams();
    params.append("project", project);
    params.append("code", code);
    params.append("state", state);
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "github/register?" + params.toString(),
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }

  // Register Github connection for project using cloud function
  async getRepos(project) {
    // Get token for authentication
    const token = await this.getToken();
    // Prepare url params
    const params = new URLSearchParams();
    params.append("project", project);
    // Make API call to remote API
    return axios({
      method: "get",
      url: this.remoteUrl + "github/repos?" + params.toString(),
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }

  // Disconnect project from Github using cloud function
  async disconnect(project) {
    // Get token for authentication
    const token = await this.getToken();
    // Prepare url params
    const params = new URLSearchParams();
    params.append("project", project);
    // Make API call to remote API
    return axios({
      method: "delete",
      url: this.remoteUrl + "github/disconnect?" + params.toString(),
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }

  // Commit file to Github using cloud function
  async commit(contents) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "github/commit",
      data: {
        contents: btoa(contents),
        project: this.projectKey,
        owner: this.owner,
        repo: this.repo,
        path: this.path,
        message: this.message,
      },
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }
}
