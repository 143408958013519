<template>
  <div class="col-12 col-md-6 col-lg-3">
    <div
      ref="card"
      :class="'card cursor-pointer' + (selected ? ' selected' : '')"
      @click="selectProject"
    >
      <!-- <img
        class="card-img-top"
        src="@/assets/img/photos/unsplash-1.jpg"
        alt="Unsplash"
      /> -->
      <avatar
        :username="project.name"
        :src="project.image"
        :rounded="false"
        :size="cardWidth"
        class="card-img-top"
      ></avatar>

      <div class="card-header px-4 pt-4">
        <div class="card-actions float-right">
          <b-dropdown
            id="more-menu"
            variant="link"
            right
            no-caret
            toggle-class="ellipsis-menu p-0"
            v-if="canEdit"
          >
            <template #button-content>
              <i class="align-middle fas fa-ellipsis-h fa-sm"></i>
            </template>
            <b-dropdown-item
              :to="{ name: 'projects-edit', params: { slug: project.path } }"
              >Edit</b-dropdown-item
            >
            <b-dropdown-item
              variant="danger"
              v-b-modal="'modal_project_delete_' + project.objectID"
              >Delete</b-dropdown-item
            >
          </b-dropdown>

          <b-modal
            :id="'modal_project_delete_' + project.objectID"
            title="Delete Project"
            header-bg-variant="danger"
            header-text-variant="light"
            ok-title="Delete"
            ok-variant="danger"
            @ok="deleteProject(project.objectID)"
          >
            <p>
              Are you sure you want to delete project {{ project.name }}? This
              cannot be undone.
            </p>
          </b-modal>
        </div>
        <h5 class="card-title mb-0">
          <b-button
            variant="link"
            size="sm"
            class="favorite-button p-0"
            @click="toggleFavorite"
            v-b-tooltip.hover.bottom
            :title="!isFavorite ? 'Mark favorite' : 'Un-favorite'"
          >
            <i class="fas fa-star" v-if="isFavorite"></i>
            <i class="far fa-star" v-if="!isFavorite"></i>
          </b-button>
          {{ project.name }}
        </h5>
      </div>
      <div class="card-body px-4 pt-2">
        <p>
          {{ project.description }}
        </p>

        <div class="d-flex pt-2">
          <div v-for="(member, index) in members" :key="index">
            <avatar
              :username="member.name"
              :src="member.avatar"
              :size="28"
              class="avatar mr-1"
            ></avatar>
          </div>
        </div>
        <!-- <img
          src="@/assets/img/avatars/avatar-3.jpg"
          class="rounded-circle mr-1"
          alt="Avatar"
          width="28"
          height="28"
        />
        <img
          src="@/assets/img/avatars/avatar-2.jpg"
          class="rounded-circle mr-1"
          alt="Avatar"
          width="28"
          height="28"
        />
        <img
          src="@/assets/img/avatars/avatar.jpg"
          class="rounded-circle mr-1"
          alt="Avatar"
          width="28"
          height="28"
        /> -->
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item px-4 pb-4">
          <p class="mb-2 font-weight-bold">
            Usage <span class="float-right">{{ projectUsage }}%</span>
          </p>
          <div class="progress progress-sm">
            <div
              :class="['progress-bar', 'bg-' + projectUsageColor]"
              role="progressbar"
              aria-valuenow="65"
              aria-valuemin="0"
              aria-valuemax="100"
              :style="{ width: projectUsage + '%' }"
            ></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import UsageMixin from "@/mixins/Usage";
import Avatar from "vue-avatar";

export default {
  name: "ProjectCard",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin, UsageMixin],
  components: {
    Avatar,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      bind: [],
      members: [],
      favorites: [],
      cardWidth: 294,
      usage: {},
      data: [],
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "config"]),
    userUid() {
      return this.userProfile.uid;
    },
    canEdit() {
      return this.project.editors.includes(this.userProfile.uid);
    },
    isFavorite() {
      let favorite = false;
      this.favorites.forEach((fav) => {
        if (fav.objectID === this.project.objectID) {
          favorite = true;
        }
      });
      return favorite;
    },
  },
  watch: {
    project: {
      immediate: true,
      handler(project) {
        this.bindObject("membership", project.objectID, "members");
        this.bindObject("usage", project.objectID, "usage");
        this.bindObject("data", project.objectID, "data");
      },
    },
    userUid: {
      immediate: true,
      handler(uid) {
        this.bindObject("favorites", uid, "favorites");
      },
    },
  },
  methods: {
    selectProject() {
      let project = { ...this.project };
      delete project.__position;
      delete project._highlightResult;
      console.log("Project", project.objectID);
      this.updateObject("selected", this.userProfile.uid, project, true);
    },
    deleteProject(projectKey) {
      Promise.all([
        this.deleteObject("projects", projectKey),
        this.deleteObject("membership", projectKey),
      ])
        .then(() => {
          this.notyf.success("The project has been deleted successfully.");
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while deleting the project. Please try again later."
          );
        });
    },
    toggleFavorite(event) {
      event.stopPropagation();
      let favorites;
      if (!this.isFavorite) {
        let project = { ...this.project };
        delete project.__position;
        delete project._highlightResult;
        favorites = [...this.favorites];
        favorites.push(project);
      } else {
        favorites = [
          ...this.favorites.filter((fav) => {
            return fav.objectID !== this.project.objectID;
          }),
        ];
      }
      this.updateObject("favorites", this.userUid, favorites, true).catch(
        (error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while favoriting the project. Please try again later."
          );
        }
      );
    },
  },
  mounted() {
    this.cardWidth = this.$refs.card.offsetWidth;
  },
};
</script>

<style lang="scss" scoped>
.card {
  max-width: 320px;
}
</style>

<style lang="scss">
.card-img-top {
  max-height: 200px;
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.ellipsis-menu {
  color: #d4d6d9;
}

.selected {
  border-color: #1863de;
  box-shadow: 0 0 0 0.2rem rgb(60, 116, 209);
}

.favorite-button {
  color: gold;
}

.favorite-button:hover {
  color: darkgoldenrod;
}
</style>
