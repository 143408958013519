<template>
  <div v-if="state" :class="suit()">
    <slot
      :items="items"
      :results="state.results"
      :is-first-page="state.isFirstPage"
      :is-last-page="state.isLastPage"
      :refine-previous="refinePrevious"
      :refine-next="refineNext"
      :refine="refineNext"
      :insights="state.insights"
    >
      <ol :class="suit('list')">
        <li
          v-for="(item, index) in items"
          :class="suit('item')"
          :key="item.objectID"
        >
          <slot
            name="item"
            :item="item"
            :index="index"
            :insights="state.insights"
            >objectID: {{ item.objectID }}, index: {{ index }}</slot
          >
        </li>
      </ol>
    </slot>
  </div>
</template>

<script>
import { createWidgetMixin } from "vue-instantsearch/src/mixins/widget";
import { connectInfiniteHitsWithInsights } from "instantsearch.js/es/connectors";
import { createSuitMixin } from "vue-instantsearch/src/mixins/suit";
export default {
  name: "CustomInfiniteHits",
  mixins: [
    createWidgetMixin({ connector: connectInfiniteHitsWithInsights }),
    createSuitMixin({ name: "InfiniteHits" }),
  ],
  props: {
    showPrevious: {
      type: Boolean,
      default: false,
    },
    escapeHTML: {
      type: Boolean,
      default: true,
    },
    transformItems: {
      type: Function,
      default(items) {
        return items;
      },
    },
    cache: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    widgetParams() {
      return {
        showPrevious: this.showPrevious,
        escapeHTML: this.escapeHTML,
        transformItems: this.transformItems,
        cache: this.cache,
      };
    },
    items() {
      // Fixes InstantSearch.js connectors API: every list
      // of things must be called `items`
      return this.state.hits;
    },
  },
  methods: {
    refinePrevious() {
      this.state.showPrevious();
    },
    refineNext() {
      this.state.showMore();
    },
  },
};
</script>
