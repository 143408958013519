<template>
  <div class="row">
    <div class="col-12 col-xl-6 mx-auto">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Import Project</h5>
          <h6 class="card-subtitle text-muted">
            Projects can be imported using a JSON file.
          </h6>
        </div>
        <div class="card-body">
          <form @submit.prevent="upload">
            <b-form-file
              v-model="file"
              accept="application/json"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              class="mb-4"
            ></b-form-file>
            <b-button type="submit" variant="primary">
              <b-spinner small v-if="uploading"></b-spinner>
              <span v-if="!uploading">
                <i class="fas fa-upload"></i> Upload
              </span>
            </b-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Projects from "@/firebase/projects";

export default {
  name: "ProjectImport",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  // components: {
  //   Avatar,
  // },
  data() {
    return {
      bind: ["slugs"],
      file: null,
      pathFormat: new RegExp(/([a-z0-9-])+/g),
      uploading: false,
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["userDisplayName", "userAvatar"]),
  },
  methods: {
    upload() {
      const reader = new FileReader();
      const file = this.file;
      console.log(file);
      // Start button loading spinner
      this.uploading = true;
      // Get storage reference
      const p = new Projects();
      reader.addEventListener(
        "load",
        (event) => {
          const text = event.target.result;
          p.import(text)
            .then((result) => {
              const path = result.data.path;
              this.navigate("projects-edit", { slug: path });
            })
            .catch((error) => {
              this.uploading = false;
              console.log(error);
              if (error.response.status === 429) {
                this.notyf.error(
                  "Unable to import project: Plan limit of owned projects exceeded"
                );
              } else {
                this.notyf.error(
                  "An error occurred while importing the project. Please try again later"
                );
              }
            });
        },
        false
      );

      if (file) {
        reader.readAsText(file);
      }
    },
  },
};
</script>
