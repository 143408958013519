var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.searchClient !== false)?_c('div',{staticClass:"container-fluid"},[_c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":_vm.searchIndex}},[_c('b-dropdown',{staticClass:"float-right mt-n1",attrs:{"split":"","split-to":{ name: 'projects-new' },"variant":"primary"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fas fa-plus"}),_vm._v(" New project ")]},proxy:true}],null,false,4208619004)},[_c('b-dropdown-item',{attrs:{"to":{ name: 'projects-import' }}},[_vm._v("Import project")])],1),_c('form',{staticClass:"float-right mt-2 mt-md-n1 mr-md-2 mb-2 mb-md-0"},[_c('ais-search-box',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var isSearchStalled = ref.isSearchStalled;
var refine = ref.refine;
return _c('div',{},[_c('input',{staticClass:"form-control",attrs:{"type":"search","placeholder":"Search projects..."},domProps:{"value":currentRefinement},on:{"input":function($event){return refine($event.currentTarget.value)}}}),_c('span',{attrs:{"hidden":!isSearchStalled}},[_vm._v("Loading...")])])}}],null,false,3132166547)})],1),_c('h1',{staticClass:"h3 mb-3"},[_vm._v("Projects")]),_c('infinite-hits',{staticClass:"mt-4",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var items = ref.items;
          var refinePrevious = ref.refinePrevious;
          var refineNext = ref.refineNext;
          var isFirstPage = ref.isFirstPage;
          var isLastPage = ref.isLastPage;
return _c('div',{},[_c('div',{staticClass:"row w-100"},[(!isFirstPage)?_c('div',{staticClass:"col text-center my-3"},[_c('b-button',{on:{"click":refinePrevious}},[_vm._v(" Show previous results ")])],1):_vm._e()]),_c('div',{staticClass:"row w-100"},_vm._l((items),function(project){return _c('project-card',{key:project.objectID,attrs:{"project":project,"selected":project.objectID === _vm.selectedProject.objectID}})}),1),_c('div',{staticClass:"row w-100"},[_c('div',{staticClass:"col text-center my-3"},[(!isLastPage)?_c('b-button',{staticClass:"mx-1",on:{"click":refineNext}},[_vm._v(" Show more results ")]):_vm._e()],1)])])}}],null,false,53273876)}),_c('ais-powered-by')],1)],1):_vm._e(),(_vm.searchClient === false)?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-center"},[_c('loader',{staticClass:"my-5",attrs:{"color":"#3F80EA"}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }